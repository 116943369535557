(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-navigation/assets/javascripts/location.js') >= 0) return;  svs.modules.push('/components/racing/race-navigation/assets/javascripts/location.js');
"use strict";

const _excluded = ["isMyGaming"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }

const {
  gameTypeToUrlName,
  urlParts,
  couponMenuItems,
  statisticsMenuItems,
  pixModes
} = svs.isServer ? require('trinidad-core').core.components.require('racing', 'common').constants : svs.racing.common.constants;
const {
  raceDefaultGameType
} = svs.isServer ? require('trinidad-core').core.components.require('racing', 'common').util : svs.racing.common.util;
const buildMyGamingLocation = _ref => {
  let {
    wagerId,
    tab,
    mode,
    type,
    wagerExamined
  } = _ref;
  let url = '/mina-spel';
  if (wagerId) {
    url += "/".concat(wagerId);
  }
  const query = new URLSearchParams();
  if (tab && tab !== 'spela') {
    query.append(urlParts.TAB, tab);
  }
  if (mode && mode !== 'active') {
    query.append(urlParts.MODE, mode);
  }
  if (type) {
    query.append(urlParts.TYPE, type);
  }
  if (wagerExamined === false) {
    query.append(urlParts.WAGER_EXAMINED, wagerExamined);
  }
  const queryStr = query.toString();
  return {
    pathname: url,
    search: queryStr ? "?".concat(queryStr) : undefined
  };
};
const buildCouponLocation = _ref2 => {
  let {
    day,
    trackName,
    raceNumber,
    gameType,
    isXpert,
    participantId,
    participantName,
    couponMenuItem,
    statisticsMenuItem,
    tab,
    assetId,
    showLiveRedirect,
    mode,
    type,
    showLive,
    row,
    activatedDrawId,
    fromPlayTogether,
    isRetailerAdmin,
    pixMode
  } = _ref2;
  let url = '';
  if (day && trackName) {
    url += "/".concat(trackName);
    if (raceNumber) {
      url += "/lopp-".concat(raceNumber);
      if (gameType) {
        url += "/".concat(gameTypeToUrlName[gameType]);
        if (isXpert) {
          url += "/".concat(urlParts.XPERTEN);
        }
      }
    }
  }
  if (day) {
    url += "/".concat(day);
  }
  if (participantId && participantName) {
    url += "/deltagare/".concat(participantName, "-").concat(participantId);
  }
  const query = new URLSearchParams();
  if (couponMenuItem && couponMenuItem !== couponMenuItems.COUPON) {
    query.append(urlParts.COUPON_MENU, couponMenuItem);
  }
  if (statisticsMenuItem && statisticsMenuItem !== statisticsMenuItems.OVERVIEW) {
    if (participantId && participantName) {
      query.append(urlParts.STATISTICS_TAB, statisticsMenuItem);
    }
  }
  if (tab && tab !== 'spela') {
    query.append(urlParts.TAB, tab);
  }
  if (mode && mode !== 'active') {
    query.append(urlParts.MODE, mode);
  }
  if (type) {
    query.append(urlParts.TYPE, type);
  }
  if (activatedDrawId) {
    query.append(urlParts.ACTIVATED_DRAW_ID, activatedDrawId);
  }
  if (fromPlayTogether) {
    query.append(urlParts.FROM_PLAY_TOGETHER, fromPlayTogether);
  }
  if (isRetailerAdmin) {
    query.append(urlParts.IS_RETAILER_ADMIN, isRetailerAdmin);
  }
  if (showLive) {
    query.append(urlParts.SHOW_LIVE, showLive);
  }
  if (assetId) {
    query.append(urlParts.ASSET_ID, assetId);
  }
  if (showLiveRedirect) {
    query.append(urlParts.SHOW_LIVE_REDIRECT, showLiveRedirect);
  }
  if (row) {
    query.append(urlParts.ROW, row);
  }
  if (!pixMode && couponMenuItem === couponMenuItems.PIX && !isXpert) {
    query.append(urlParts.PIX_MODE, pixModes.FROM_SCRATCH);
  } else if (pixMode && couponMenuItem === couponMenuItems.PIX && !isXpert) {
    query.append(urlParts.PIX_MODE, pixMode);
  }
  const queryStr = decodeURIComponent(query.toString());
  return {
    pathname: url,
    search: queryStr ? "?".concat(queryStr) : undefined
  };
};
const buildLocation = _ref3 => {
  let {
      isMyGaming
    } = _ref3,
    options = _objectWithoutProperties(_ref3, _excluded);
  if (isMyGaming) {
    return buildMyGamingLocation(options);
  }
  return buildCouponLocation(options);
};
const buildLocationFromRace = (race, jackpotGameType) => buildCouponLocation({
  day: race.meeting.formattedDate,
  trackName: race.meeting.track.urlName,
  raceNumber: race.raceNumber,
  gameType: raceDefaultGameType(race, jackpotGameType)
});
const buildLocationFromRaceWithQueryParameters = function (race) {
  let queryParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return buildCouponLocation(_objectSpread({
    day: race.meeting.formattedDate,
    trackName: race.meeting.track.urlName,
    raceNumber: race.raceNumber,
    gameType: raceDefaultGameType(race)
  }, queryParams));
};
const buildLocationFromRaceParticipant = (race, participant) => buildCouponLocation({
  day: race.meeting.formattedDate,
  trackName: race.meeting.track.urlName,
  raceNumber: race.raceNumber,
  gameType: raceDefaultGameType(race),
  participantId: participant.participantId,
  participantName: participant.horseNameUrl
});
const buildLocationFromRaceWithShowLive = race => buildCouponLocation({
  day: race.meeting.formattedDate,
  trackName: race.meeting.track.urlName,
  raceNumber: race.raceNumber,
  gameType: raceDefaultGameType(race),
  showLive: true
});
const buildLocationFromRaceWithRow = (race, row) => buildCouponLocation({
  day: race.meeting.formattedDate,
  trackName: race.meeting.track.urlName,
  raceNumber: race.raceNumber,
  gameType: raceDefaultGameType(race),
  row
});
const locationToUrl = location => {
  let url = location.pathname;
  if (location.search) {
    url += location.search;
  }
  return url;
};
const exported = {
  buildLocation,
  buildCouponLocation,
  buildLocationFromRace,
  buildLocationFromRaceParticipant,
  buildLocationFromRaceWithShowLive,
  buildLocationFromRaceWithRow,
  buildLocationFromRaceWithQueryParameters,
  buildMyGamingLocation,
  locationToUrl
};
if (svs.isServer) {
  module.exports = exported;
} else {
  setGlobal('svs.racing.navigation.location', exported);
}

 })(window);